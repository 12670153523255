<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <div class="w-full h-full pt-2">
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({
              name: 'EmployeeDirectory'
            })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />
        <h1 class="text-xl  text-left font-extrabold ml-4 mt-3 mr-8">
          Create Employee
        </h1>
      </div>
      <div style="height:100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <card v-else class="mx-3 p-5 mt-1 mb-4">
        <div class="flex">
          <p class="flex-grow text-lg font-bold">
            Personal Information
          </p>
          <div class="flex">
            <card class="w-10 text-xl mr-5 font-extrabold h-10">
              <p class="text-center my-2">
                1
              </p>
            </card>
            <div
              class="flex mt-2 "
              :class="{ 'cursor-pointer': $route.params.id || allowNextPage }"
              @click="handleSwitch"
            >
              <div class="font-10 mr-4">
                <p class="text-flame">
                  Next
                </p>
                <p class=" uppercase font-semibold">
                  Employment Details
                </p>
              </div>
              <Icon size="xs" icon-name="icon-arrow-right" />
            </div>
          </div>
        </div>
        <image-upload
          @file="employeeBasic.basic.photo = $event"
          :photo="employeeBasic.basic.photo"
          :url="url"
          :name="`${employeeBasic.basic.fname} ${employeeBasic.basic.lname}`"
        />
        <div class=" mt-6">
          <p class=" font-semibold mb-7">
            Bio Data
          </p>
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top:1.125rem"
          >
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                :rules="['required']"
                label="First Name"
                v-model="employeeBasic.basic.fname"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Middle Name"
                v-model="employeeBasic.basic.mname"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                :rules="['required']"
                variant="w-full h-10"
                label="Last Name"
                v-model="employeeBasic.basic.lname"
              />
            </div>
            <div class="col-span-3">
              <c-select
                placeholder="--Enter--"
                variant="w-full h-10"
                :options="gender"
                label="Gender"
                v-model="employeeBasic.basic.gender"
              />
            </div>
            <div class="col-span-3">
              <label class="date-label">Date of Birth</label>
              <div class="date-flex">
                <datepicker
                  placeholder="--Enter--"
                  input-class="date-input"
                  type="date"
                  required
                  style="width:100%; outline:none"
                  v-model="employeeBasic.basic.dob"
                />
              </div>
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                type="number"
                label="Phone Number"
                v-model="employeeBasic.basic.phoneNo"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Work Email"
                :rules="EmailRule"
                v-model="email"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Personal Email"
                v-model="employeeBasic.basic.alternateEmail"
              />
            </div>
          </div>
          <divider class="my-8" />
          <p class=" font-semibold mb-7">
            Address
          </p>
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top:1.125rem"
          >
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Address"
                v-model="employeeBasic.basic.address"
              />
            </div>
            <div class="col-span-3">
              <c-select
                placeholder="--Enter--"
                variant="w-full"
                :options="countrySelect"
                @change="changeState"
                label="Country"
                v-model="employeeBasic.basic.country"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="City"
                v-model="employeeBasic.basic.city"
              />
            </div>
            <div class="col-span-3">
              <c-select
                placeholder="--Enter--"
                v-if="employeeBasic.basic.country === 'NGA'"
                variant="w-full h-10"
                label="State"
                :options="$store.state.states"
                v-model="employeeBasic.basic.state"
              />
              <c-text
                placeholder="--Enter--"
                v-else
                variant="w-full h-10"
                label="State"
                v-model="employeeBasic.basic.state"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Zip Code"
                type="number"
                v-model="employeeBasic.basic.zip"
              />
            </div>
          </div>
          <divider class="my-8" />
          <p class=" font-semibold mb-7">
            Next of Kin
          </p>
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top:1.125rem"
          >
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="First Name"
                v-model="employeeBasic.basic.nokFname"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Last Name"
                v-model="employeeBasic.basic.nokLname"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Phone Number"
                v-model="employeeBasic.basic.nokPhone"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Email"
                v-model="employeeBasic.basic.nokEmail"
              />
            </div>
            <div class="col-span-3">
              <c-select
                placeholder="--Enter--"
                variant="w-full h-10"
                :options="$store.state.nok"
                label="Relationship"
                v-model="employeeBasic.basic.nokRelationship"
              />
            </div>
            <div class="col-span-3">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Address"
                v-model="employeeBasic.basic.nokAddress"
              />
            </div>
          </div>
          <divider class="my-8" />
          <div class="mb-6">
            <p class="text-darkPurple text-base mb-4">
              Generate and share credentials with employee
            </p>
            <RadioButton
              :options="credentialOptions"
              col-span="col-span-1 "
              class="ml-2 text-base text-darkPurple"
              v-model="employeeBasic.basic.canLogin"
              space-between="mr-2"
            />
            <small v-if="!employeeBasic.basic.canLogin" class="text-flame"
              >Please select one</small
            >
          </div>
          <div class="flex mt-4">
            <div class="flex flex-grow">
              <Button
                width="7.875rem"
                @click="handleSubmit(handleSaveNow)"
                :class="`text-white buttons`"
                :background="appearance.buttonColor || defaultBTNColor"
                :disabled="disableBtn"
              >
                Next
              </Button>
              <Button
                width="7.875rem"
                @click="$router.push({ name: 'EmployeeDirectory' })"
                class="buttons"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                @click="handleSubmit(handleSaveLater)"
                :background="appearance.buttonColor || defaultBTNColor"
                class=" text-white buttons"
              >
                Save & Finish later
              </Button>
            </div>
          </div>
        </div>
      </card>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Datepicker from "vuejs-datepicker";
import ImageUpload from "@/components/imageUpload";
import gender from "@/helpers/gender";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import Divider from "@/components/divider";
import Button from "@/components/Button";

export default {
  name: "PersonalInfo",
  components: {
    ValidationObserver,
    Card,
    ImageUpload,
    CText,
    BackButton,
    CSelect,
    Datepicker,
    RadioButton,
    Button,
    Divider
  },

  data() {
    return {
      disableBtn: false,
      gender,
      photo: "",
      email: "",
      error: [],
      timeout: null,
      url: process.env.VUE_APP_IMAGE_UPLOAD,
      countrySelect: [],
      allowNextPage: false,
      loading: false,
      credentialOptions: [
        { name: "Yes", radioName: "credential", value: true },
        { name: "No", radioName: "credential", value: false }
      ],
      EmailRule: [
        "required",
        {
          name: "email",
          rule: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        },
        {
          name: "available",
          rule: v => this.handleEmail(v)
        }
      ],
      showStates: true,
      showCredentialError: false,
      employeeBasic: {
        basic: {
          photo: null,
          fname: "",
          lname: "",
          mname: "",
          dob: null,
          gender: "",
          email: "",
          alternateEmail: "",
          phoneNo: "",
          alternatePhoneNo: "",
          nokFname: "",
          nokLname: "",
          nokMname: "",
          nokEmail: "",
          nokAddress: "",
          nokRelationship: "",
          nokPhone: "",
          address: "",
          country: "",
          canLogin: null,
          state: "",
          city: "",
          zip: "",
          iso2: "",
          dialCode: ""
        }
      }
    };
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    async handleEmail(value) {
      const result = await this.$_checkEmailExist(value);
      if (result.data.emailExists) {
        return "Email already exists";
      }
      return true;
    },
    handleSave() {
      this.employeeBasic.basic.containsBio = true;
      this.$_editOneEmployee({
        id: this.$route.params.id,
        employeeData: this.employeeBasic.basic
      });
    },
    handleSaveNow() {
      this.createEmployees("next");
    },
    handleSaveLater() {
      this.createEmployees("later");
    },
    convertValue(value) {
      if (value === "true" || value === true) {
        return true;
      }
      return false;
    },
    createEmployees(type) {
      if (this.employeeBasic.basic.canLogin === null) {
        this.showCredentialError = true;
        this.$toasted.error("Please select if employee can get credentials", {
          duration: 3000
        });
        return;
      }
      this.employeeBasic.basic.canLogin = this.convertValue(
        this.employeeBasic.basic.canLogin
      );
      this.disableBtn = true;
      this.employeeBasic.basic.createdBy = this.$AuthUser.id;
      this.employeeBasic.basic.orgId = this.$AuthUser.orgId;
      this.employeeBasic.basic.email = this.email;
      this.employeeBasic.basic.dob = this.employeeBasic.basic.dob
        ? this.employeeBasic.basic.dob.toString()
        : null;
      this.employeeBasic.basic.containsBio = true;
      this.$_createEmployee(this.employeeBasic)
        .then(res => {
          if (type === "next") {
            this.$router.push({
              name: "EditPersonalInfo",
              params: {
                id: res.data.userId
              },
              query: {
                currentTab: "Employment Details",
                user: `${this.employeeBasic.basic.fname} ${this.employeeBasic.basic.lname}`
              }
            });
          } else {
            this.$router.push({ name: "EmployeeDirectory" });
          }
          this.creatingEmployee = false;
          this.disableBtn = false;
        })
        .catch(err => {
          this.$toasted.error("Employee not created successfully", {
            duration: 3000
          });
          this.disableBtn = false;
          throw new Error(err);
        });
    },
    changeState(value) {
      if (value === "NGA") {
        this.showStates = true;
      } else this.showStates = false;
    },
    handleSwitch() {
      if (this.$route.params.id || this.allowNextPage) {
        this.$router.push({
          name: "EditEmploymentDetails",
          params: { id: this.$route.params.id }
        });
      }
    },
    getPersonalInfo() {
      this.$_getOneEmployeeAll({
        id: this.$route.params.id
      }).then(({ data }) => {
        this.employeeBasic.basic = data;
        this.email = this.employeeBasic.basic.email;
        if (this.employeeBasic.basic.state) {
          this.$store.state.states.find(element => {
            if (element.id === this.employeeBasic.basic.state) {
              this.employeeBasic.basic.state = element.name;
            }
            return this.employeeBasic.basic.state;
          });
        }
        this.loading = false;
      });
    },
    editAnnualGross() {
      this.isDisabled = false;
      this.isEdit = false;
    },
    saveAnnualGross() {
      this.isDisabled = true;
      this.isEdit = true;
    }
  },
  mounted() {
    this.countrySelect = this.$store.state.countries.map(element => ({
      id: element.Alpha3Code,
      name: element.Name
    }));
    if (this.$route.params.id) {
      this.loading = true;
      this.getPersonalInfo();
    }
  }
};
</script>
